import React, { useState } from 'react'
import ScrollLock from 'react-scrolllock'
import { TiSocialFacebook } from '@react-icons/all-files/ti/TiSocialFacebook'
import { TiSocialInstagram } from '@react-icons/all-files/ti/TiSocialInstagram'
import { MdSchool } from '@react-icons/all-files/md/MdSchool'
import { VscMenu } from '@react-icons/all-files/vsc/VscMenu'
import { VscClose } from '@react-icons/all-files/vsc/VscClose'
import MailTo from 'src/components/MailTo'
import Link from 'src/components/Link'

const items: { label: string; path: string }[] = [
  { label: 'home', path: '/' },
  { label: 'works', path: '/works' },
  { label: 'essays', path: '/essays' },
]

const sns = {
  fb: 'https://www.facebook.com/formothree',
  ig: 'https://www.instagram.com/formosa.in.black/',
  blog: 'https://kyleideta.study'
}

const MobileMenuContents: React.FC = () => (
  <ScrollLock>
    <div className="h-screen w-screen fixed top-0 left-0 bg-white z-10 bg-opacity-95 text-center">
      <ul className="text-3xl flex flex-col h-full justify-center tracking-widest">
        {items.map(item => (
          <li key={item.label} className="py-6">
            <Link to={item.path} className="py-6">
              {item.label}
            </Link>
          </li>
        ))}
        <li className="py-6">
          <a href="https://photo.kyleideta.art" target="_blank" className="py-6">
            photos
          </a>
        </li>
        <li className="py-6">
          <MailTo email="hello@kyleideta.art" className="py-6">
            contact
          </MailTo>
        </li>
        <li className="py-10 flex justify-around items-center">
          <a href={sns.blog} target="_blank" rel="noreferrer">
            <MdSchool />
          </a>
          <a href={sns.ig} target="_blank" rel="noreferrer">
            <TiSocialInstagram />
          </a>
          <a href={sns.fb} target="_blank" rel="noreferrer">
            <TiSocialFacebook />
          </a>
        </li>
      </ul>
    </div>
  </ScrollLock>
)

export const HeaderMenu: React.FC = () => (
  <ul className="text-lg tracking-widest text-right border-r border-black pr-4 flex-1 justify-self-end flex flex-col justify-end">
    {items.map(item => (
      <li key={item.label} className="pt-6">
        <Link to={item.path} className="pt-3">
          {item.label}
        </Link>
      </li>
    ))}
    <li className="pt-6">
      <a href="https://photo.kyleideta.art" target="_blank" className="pt-3">
        photos
      </a>
    </li>
    <li className="pt-6">
      <MailTo email="formothree@gmail.com" className="pt-3">
        contact
      </MailTo>
    </li>
    <li className="pt-12 flex justify-end items-center">
      <a href={sns.blog} target="_blank" rel="noreferrer" className="mr-3">
        <MdSchool />
      </a>
      <a href={sns.ig} target="_blank" rel="noreferrer" className="mr-3">
        <TiSocialInstagram />
      </a>
      <a href={sns.fb} target="_blank" rel="noreferrer">
        <TiSocialFacebook />
      </a>
    </li>
  </ul>
)

export const MobileHeaderMenu: React.FC = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      {open ? (
        <VscClose
          onClick={() => setOpen(false)}
          className="absolute top-0 right-0 z-20"
        />
      ) : (
        <VscMenu
          onClick={() => setOpen(true)}
          className="absolute top-0 right-0 z-20"
        />
      )}
      {open && <MobileMenuContents />}
    </>
  )
}
