import React from 'react'
import Header from './Header'
import { MobileHeaderMenu } from 'src/components/HeaderMenu'
import Language from 'src/components/Language'
import 'tailwindcss/tailwind.css'
import 'fontsource-crimson-text'
import 'fontsource-buenard/700.css'
import 'fontsource-noto-serif-jp/500.css'
import 'src/global.css'

const Layout: React.FC = ({ children }) => {
  return (
    <div className="container mx-auto py-5 px-5 md:py-20 md:px-10">
      <div className="grid grid-cols-11 md:grid-cols-4 min-h-3/4">
        <div>
          <Header />
        </div>
        <div className="col-span-10 md:col-span-3 pl-4 md:pl-20 relative pt-16 md:pt-0">
          <div className="block md:hidden">
            <MobileHeaderMenu />
          </div>
          <main className="pb-10 md:pb-24">{children}</main>
          <footer className="pt-3 border-t border-black text-sm flex justify-end">
            <div className="flex-grow">
              <Language />
            </div>
            <div className="text-right">
              © {new Date().getFullYear()},{` `}
              <a href="https://kyleideta.art">Kyle Ideta</a>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Layout
