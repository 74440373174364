import React from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { HeaderMenu } from 'src/components/HeaderMenu'
import Name from 'src/components/svg/Name'
import Fib from 'src/components/svg/Fib'

const Header: React.FC = () => {
  const { i18n } = useTranslation()
  const { pathname } = useLocation()
  const isEssay = pathname.includes('essays')
  const isEn = !i18n.language.includes('ja-') && !i18n.language.includes('zh-')
  return (
    <header className="flex h-full max-h-160">
      <div className="flex-1">
        <Link to="/">{isEssay ? <Fib /> : <Name isEn={isEn} />}</Link>
      </div>
      <div className="flex-1 h-3/4 self-end hidden md:flex">
        <HeaderMenu />
      </div>
    </header>
  )
}

export default Header
