import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'
import clsx from 'clsx'
import Link from 'src/components/Link'
import {
  defaultLanguage,
  shouldHaveTranslation,
  availableLanguages,
} from '../../locales'

const Language: React.FC = () => {
  const { i18n } = useTranslation()
  const { pathname } = useLocation()
  useEffect(() => {
    const currentLanguage = availableLanguages.reduce((acc, cur) => {
      return pathname.includes(cur.key) ? cur.key : acc
    }, defaultLanguage)
    i18n.changeLanguage(currentLanguage)
  }, [pathname])

  return (
    <ul className="flex">
      {availableLanguages.map(lng => {
        if (!shouldHaveTranslation(pathname) && lng.key !== defaultLanguage)
          return null
        return (
          <li
            key={lng.key}
            className={clsx(
              'pr-3 md:pr-8',
              lng.key === i18n.language ? '' : 'text-gray-300'
            )}
          >
            <Link to={pathname} language={lng.key}>
              {lng.label}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default Language
