import React from 'react'
import { Link as BaseLink, GatsbyLinkProps } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { generatePath } from '../../locales'
import { SitePageContext } from 'types/graphql-types'

interface LinkProps extends Omit<GatsbyLinkProps<{}>, 'ref'> {
  language?: SitePageContext['language']
}

const Link: React.FC<LinkProps> = ({ children, to, language, ...rest }) => {
  const { i18n } = useTranslation()
  return (
    <BaseLink to={generatePath(to, language || i18n.language)} {...rest}>
      {children}
    </BaseLink>
  )
}

export default Link
