import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import OgImage from 'src/images/og_image.jpg'
// @ts-ignore
import TwitterImage from 'src/images/twitter_image.jpg'

type SEOProps = {
  description?: string | null
  meta?: { name: string; content: string }[]
  title?: string | null
}

const SEO: React.FC<SEOProps> = ({ description, meta = [], title }) => {
  const { site } = useStaticQuery(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const { i18n } = useTranslation()
  const metaDescription: string = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const snsTitle = `${title ? `${title} | ` : ''}${defaultTitle}`
  const metaMap: Map<string, string | undefined> = new Map([
    ['description', metaDescription],
    ['og:title', snsTitle],
    ['og:description', metaDescription],
    ['og:type', 'website'],
    ['og:image', `https://kyleideta.art${OgImage}`],
    ['twitter:card', `summary_large_image`],
    ['twitter:image', `https://kyleideta.art${TwitterImage}`],
    ['twitter:creator', site.siteMetadata?.author],
    ['twitter:title', snsTitle],
    ['twitter:description', metaDescription],
    ['twitter:site', site.siteMetadata?.author],
  ])

  meta.forEach(item => {
    if (!item.content) return
    metaMap.set(item.name, item.content)
  })

  return (
    <Helmet
      htmlAttributes={{
        lang: i18n.language,
      }}
      defaultTitle={defaultTitle}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      title={title!}
      meta={Array.from(metaMap).map(item => ({
        name: item[0],
        property: item[0],
        content: item[1],
      }))}
    />
  )
}

export default SEO
